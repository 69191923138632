import React from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

import icon from 'images/carousel-left.png'

export default function ContactMap() {
  const customIcon = new L.Icon({
    iconUrl: icon,
    iconSize: [25, 25]
  })

  const positionUae = [25.6562504, 55.7836879]

  const bounds = [positionUae]

  return (
    <div style={{ height: 340 }}>
      <MapContainer
        bounds={bounds}
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        <Marker
          icon={customIcon}
          position={positionUae}
        >
          <Popup>
            <p>
              <b>Head office & factory</b>
              <br />
              <br />
              Plot # 152-163, Al Jazeerah Al Hamra,
              <br />
              Ras Al Khaimah, UAE
              <br />
              <br />
              Tel: + (971-7) 244-7464
              <br />
              Fax: + (971-7) 244-7277
              <br />
              Email: sales@mabanisteel.com
            </p>
          </Popup>
        </Marker>
      </MapContainer>
    </div>
  )
}
