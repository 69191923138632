import React from 'react'
import CountUp from 'react-countup'
import { useInView } from 'react-intersection-observer'

export default function Countup({ number }) {
  const [ref, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
  })

  return (
    <div ref={ref}>
      <CountUp end={inView ? number : 0} separator="," />
    </div>
  )
}
