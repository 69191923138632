import React, { lazy, Suspense } from 'react'
const Slider = lazy(() => import(/* webpackChunkName: "Slider" */'react-slick'))
import MabaniSlide1 from 'images/mabani/mabani-slide1.jpg'
import MabaniSlide2 from 'images/mabani/mabani-slide2.jpg'
import MabaniSlide3 from 'images/mabani/mabani-slide3.jpg'
import MabaniSlide4 from 'images/mabani/mabani-slide4.jpg'

export default function AutoSlider({ arrows = true }) {
  const settings = {
    autoplay: true,
    arrows,
    dots: false,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <Suspense fallback={<div></div>}>
      <Slider {...settings}>
        <div className="sm:w-11/12" >
          <img className="w-full h-full" src={MabaniSlide2} alt="mabani-slide2"/>
        </div>
        <div className="sm:w-11/12" >
          <img className="w-full h-full" src={MabaniSlide1} alt="mabani-slide1"/>
        </div>
        <div className="sm:w-11/12" >
          <img className="w-full h-full" src={MabaniSlide3} alt="mabani-slide3"/>
        </div>
        <div className="sm:w-11/12" >
          <img className="w-full h-full" src={MabaniSlide4} alt="mabani-slide4"/>
        </div>
      </Slider>
    </Suspense>
  )
}