import React, { lazy, Suspense, useEffect, useState } from 'react'
const RedSelect = lazy(() => import(/* webpackChunkName: "RedSelect" */'../../shared/RedSelect'))

export default function Filter({ regions, params, sectors }) {
  const [product_type, setProductType] = useState(params.product_type || undefined)
  const [region_id, setRegionId] = useState(params.region_id)
  const [sector_id, setSectorId] = useState(params.sector_id || undefined)
  const [first_run, setFirstRun] = useState(true)
  const [sectorSelection, setSectorSelection]  = useState([{ value: undefined, label: 'Project Sector - All' }].concat(sectors.map(sector => ({ value: sector.id, label: sector.name }))))

  const options = [{ value: undefined, label: 'Project type - All' }, { value: 'pebss', label: 'PEBSS' }, { value: 'hrss', label: 'HRSS' }, { value: 'ba', label: 'Building Accessories' }]

  const onProductTypeChange = ({ value }) => setProductType(value)
  const handleClick = regionId => () => setRegionId(regionId)
  const selectedProductType = () => options.find(option => option.value === product_type)
  const selectedSector = () => sectorSelection.find(sector => sector.value === sector_id)
  const onSectorChange = ({ value }) => setSectorId(value)

  useEffect(() => {
    if(!first_run) {
      const query_object = {}
      if(product_type) query_object.product_type = product_type
      if(region_id) query_object.region_id = region_id
      if(sector_id) query_object.sector_id = sector_id
      const query_string = new URLSearchParams(query_object).toString()
      window.location = '/projects' + query_string ? `?${query_string}` : ''
    } else {
      setFirstRun(false)
    }
  }, [product_type, region_id, sector_id])

  return (
    <React.Fragment>
      <div className="mb-4 sm:mb-0 sm:w-3/12">
        <Suspense fallback={<div></div>}>
          <RedSelect className="w-full" value={selectedProductType()} options={options} onChange={onProductTypeChange}/>
        </Suspense>
      </div>
      <div className="sm:w-3/12">
        <Suspense fallback={<div></div>}>
          <RedSelect className="w-full" value={selectedSector()} options={sectorSelection} onChange={onSectorChange}/>
        </Suspense>
      </div>
      <div className="font-bold lg:mt-0 mt-5 sm:text-xl sm:text-left text-center text-xs">
        <button className={`mr-4 ${region_id ? 'text-cod-gray-o-50' : 'text-primary' }`} onClick={handleClick(undefined)}>All</button>
        { regions.map(region => (
          <button key={region.id} className={`mr-4 ${+region_id === region.id ? 'text-primary' : 'text-cod-gray-o-50' }`} onClick={handleClick(region.id)}>{region.name}</button>
        ))}
      </div>
    </React.Fragment>
  )
}