import React, { lazy, Suspense } from 'react'
import NotificationProvider from '../../../contexts/NotificationContext'
const ContactForm = lazy(() => import(/* webpackChunkName: "ContactForm" */'../../shared/contact/ContactForm'))

export default function ContactFormWrapper() {

  return (
    <Suspense fallback={<div></div>}>
      <NotificationProvider>
        <ContactForm siteName="Mabani" />
      </NotificationProvider>
    </Suspense>
  )
}