import React, { lazy, Suspense } from 'react'
const ClientLogos = lazy(() => import('../../shared/ClientLogos'))

export default function Clients({ logos }) {
  return (
    <Suspense fallback={<div></div>}>
      <ClientLogos capitalize disableOnclick logos={logos} />
    </Suspense>
  )
}
